/*===========================
Pricing
===========================*/

// .pricing-item{
//     border:1px solid #eee;
//     padding:50px 60px;
//     position: relative;
//     transition: all .4s ease;
//     border-radius: 5px;
//     overflow: hidden;
  
//     &:before{
//         position: absolute;
//         content:"";
//         bottom:0px;
//         left:0px;
//         width:0px;
//         height:2px;
//         background: $primary-color;
//         transition: all .4s ease;
//     }

//     .pricing-header p{
//         transition: all .4s ease;
//         text-transform: uppercase;
//         letter-spacing: 2px;
//         font-size: 14px;
//         color:#000;
//     }
//     &.featured{
//         background: rgba(0,0,0,0.02);
//     }

//     .pricing-header{
//         margin-bottom: 20px;
//     }

//     .price{
//         margin-bottom: 30px;
//         border-bottom:1px solid #eee;
//         padding-bottom: 30px;
//         h2{
//             font-size: 50px;
//             letter-spacing: -.5px;
//         }
//         small{
//             font-size: 16px;
//             font-weight: 400;
//         }
//     }

//     .btn{
//         margin:30px 0px;
//     }
    
//     &:hover{
//         background: $light;
//         box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);

//         &:before{
//             width:100%;
//         }

//         &.featured{
//             background: $light;
//         }

//         .pricing-header p{
//             color: $primary-color;
//         }
//     }
// }


// .pricing-features{
//     ul li{
//         margin-bottom: 10px;
//         color:#000;
//         i{
//             margin-right: 10px;
//             color:$primary-color;
//         }
//     }
// }



.pricing{
    position: relative;
    background: $light;
    z-index: 2;
    &:after{
        position: absolute;
        content:"";
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 40%;
        background: url("../images/bg/shape-bg-1.png")no-repeat;
        z-index: -1;
    }
}



.pricing-item{
    border:2px solid #F4F7FC;
    padding:50px 60px;
    position: relative;
    transition: all .4s ease;
    border-radius: 5px;
    overflow: hidden;
    background: $light;
  
    &:before{
        position: absolute;
        content:"";
        bottom:0px;
        left:0px;
        width:0px;
        height:2px;
        background: $primary-color;
        transition: all .4s ease;
    }

    .pricing-header {
        margin-bottom: 20px;

        p{
            transition: all .4s ease;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 14px;
            color: $secondary-color;
        }

        .badge{
            background: $primary-color;
            font-weight: 600;
            padding: 6px 10px;
            transition: all .4s ease;
            color: #fff;
            position: absolute;
            right: 20px;
            top: 20px;
        }

    }
  
    .divider{
        width: 20%;
        background: $primary-color;
        height: 2px;
    }
    .price{
        padding-bottom: 30px;
        h2{
            font-size: 50px;
            letter-spacing: -.5px;
            transition: all .4s ease;
        }
        small{
            font-size: 16px;
            font-weight: 400;
        }
    }

    .btn{
        margin:30px 0px;
    }
    
    &:hover{
        background: $light;
        box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);
        border-color: $light;

        &:before{
            width:100%;
        }


        h2{
            color: $primary-color;
        }
       
        // .badge{
        //     background: $primary-color;
        // }
    }
}


.pricing-features{
    ul li{
        margin-bottom: 10px;
        color:#000;
        i{
            margin-right: 10px;
            color:$primary-color;
        }
    }
}

.pricing-tab .nav-link {
	font-size: 14px;
	line-height: 1.33;
	color: #bbbbbb;
	font-weight: 400;
	border: solid 1px transparent;
    text-transform: capitalize;
}

.pricing-tab .nav-link.active, .pricing-tab .show > .nav-link {
    color: #273444;
    font-weight: 500;
    box-shadow: 0 2px 20px 0 rgba(97, 97, 97, 0.07);
    border-color: $primary-color;
    background-color: $primary-color;
    color: $light;
}
