
/*========================
Content tab
=============================*/

.tutori-course-content{
    .learn-press-nav-tabs .course-nav {
        a{
            color:#ababab;
            font-size: 18px;
            font-weight: 700;
            transition: all .4s ease 0s;
            border:0px;
            border-bottom:2px solid transparent;
            padding:15px 20px;
        }
        &.active {
            a{
                color:#000;
                transition: all .4s ease 0s;
            }
            &:hover a{
                color:var(--theme-primary-color);
            }
        }
        
    }

    .learn-press-nav-tabs  .nav-tabs{
        transition: all .4s ease 0s;
        margin-bottom:40px;
        border-bottom:2px solid #ddd;
    }

    .nav-tabs .nav-link {
        border: 2px solid transparent;
    }
    


    .learn-press-nav-tabs .course-nav.active::after, 
   .learn-press-nav-tabs .course-nav:hover::after {
        background: var(--theme-primary-color);
        height: 2px;
        bottom:-2px;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #222;
        border: 0px;
        border-bottom:2px solid var(--theme-primary-color);
    }

   
    
}



/*==========================
Course Topics
================================*/


.tutori-course-curriculum ul.curriculum-sections .section{
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
}
.tutori-course-curriculum ul.curriculum-sections .section-content .course-item .section-item-link::before {
	font-family:"Font Awesome 5 Pro";
	font-size: 14px;
	left: 0;
	display: table-cell;
	width: 20px;
    padding: 10px 0;
    color:rgb(105, 105, 105);
    transition: all .4s ease 0s;
}

.tutori-course-curriculum ul.curriculum-sections .section-content .course-item.course-item-lp_lesson .section-item-link::before {
	content: "\f144";
}

.tutori-course-curriculum ul.curriculum-sections .section-content .course-item.course-item-lp_quiz .section-item-link::before {
	content: "\f017";
}
.tutori-course-curriculum ul.curriculum-sections .section-content .course-item:nth-child(2n+1) a {
	background-color: #f8f8f8;
}
.tutori-course-curriculum ul.curriculum-sections .section-content .course-item.course-item-lp_assignment .section-item-link::before {
	content: "\f15c";
}





/*==========================
Course Curriculam
==========================*/
.tutori-course-curriculum {

    ul.curriculum-sections .section-header {
        padding:10px 40px;
        display: table;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .section-meta {
            display: table-cell;
            white-space: nowrap;
            padding-left: 20px;
            text-align: right;
            font-size: 14px;
            vertical-align: middle;
            .section-progress {
                display: inline-block;
                margin-right: 5px;
                .progress-bg {
                    width: 100px;
                }
            }
        }
        .section-title {
            font-weight: 700;
            margin-bottom: 0;
            font-size: 18px;
            padding: 10px 0;
        }
        .section-left {
            display: table-cell;
            vertical-align: top;
            cursor: pointer;
        }
       
    }
    
}


.tutori-course-curriculum  ul.curriculum-sections .section-header .section-desc, 
.tutori-course-curriculum  ul.curriculum-sections .section-header .section-title {
	margin: 0 0 10px 0;
}

.tutori-course-curriculum  ul.curriculum-sections .section-header .section-title{
    color: #000;
    text-transform: capitalize;
}

.tutori-course-curriculum  ul.curriculum-sections .section-header .section-desc{
    font-weight: 400;
}

.tutori-course-curriculum  ul.curriculum-sections .section-content {
	list-style: none;
	margin: 0 0 15px 0;
    padding: 0;
    .course-item {
        position: relative;
        font-size: 14px;
        transition: padding-left linear .15s;
        background: #fff;
        margin: 0;
       .item-icon{
            display: table-cell;
            vertical-align: middle;
            padding: 10px 0px;
       }
       
        .section-item-link {
            border-bottom: none;
            display: table;
            box-shadow: none;
            outline: 0;
            width: 100%;
            line-height: 1.5;
            transition: all .4s ease 0s;
            padding:7px 40px;
            color: #7A7A7A;
            text-transform: capitalize;

            &:hover{
                .item-name{
                    color:var(--theme-primary-color);
                }
                &:before{
                    color:var(--theme-primary-color);
                }
            }

            .item-name{
                color: #7A7A7A;
                transition: all .4s ease 0s;
                display: table-cell;
                vertical-align: middle;
                padding: 10px 10px;
                font-size: 16px;
            }
            .course-item-meta {
                display: table-cell;
                vertical-align: middle;
                white-space: nowrap;
                padding: 10px 0;
                text-align: right;
                 .item-meta {
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 5px;
                    padding: 0 8px;
                    border-radius: 3px;
                    font-size: 12px;
                    color: var(--theme-primary-color);
                    background: #f4ebe7;
                    &.duration{
                        background: rgba(1, 90, 189,.1);
                        color: #000;
                    }
                }
                .course-item.item-preview .course-item-status {
                    background: var(--theme-primary-color);
                    font-style: normal;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;
                }
               .course-item-status {
                    color: #ddd;
                    margin-left: 5px;
                    display: none;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }
               .course-item.has-status .course-item-status {
                    display: inline-block;
                }
            }
        }
    }
}


/*-------------------
Course Thumbnail
-------------------------*/

.course-thumbnail{
    img{
        width:100%;
        border-radius: 5px;
    }
    margin-bottom:30px;
}



/*-------------------
Instructor
-------------------------*/
.instructor-image{
    img{
        max-width: 100%;
        height: auto;
        border-radius: 5px;
    }
}
.instructor-content h4{
    text-transform: capitalize;
}
.user-social-links{
    li{
        display:inline-block;
        a{
            width: 30px;
            height: 30px;
            text-align: center;
            font-size: 15px;
            color: #221638;
            position: relative;
            border-radius: 2px;
            background-color: #e1e1e1;
            display:inline-block;

            &:hover{
                background:var(--theme-primary-color);
                color:#fff;
            }
        }
    }
}


/*========================
Course Review
==============================*/

.course-review-head{
    margin-bottom: 20px;
}
.course-single-review{
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 40px;

    .review-title{
        color: #666;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .user-image{
        float: left;
        margin-right: 40px;
        img{
            border-radius: 100%;
            width: 70px;
            height: 70px;
        }
    }

    .user-review-content{
        overflow: hidden;
    }
}


.course-reviews-list li .user-name,  .course-reviews-list-shortcode li .user-name {
    text-transform: capitalize;
    margin:0px!important;
    font-size: 20px;
}
.course-reviews-list li .review-title, .course-reviews-list-shortcode li .review-title {
	font-style:normal!important;
}

 .course-reviews-list li, .course-reviews-list-shortcode li {
    margin-bottom: 20px;
}

#course-reviews{
    margin-top:40px;
}

