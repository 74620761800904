/*------------------------
Portfolio
--------------------*/

.portfolio{
    position: relative;
    &:before{
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 70%;
        background:#F8F8F8;
        z-index: -1;
    }
   
}

.portfolio-single{
    border:8px solid $light;
    margin-bottom: 25px;
    .portfolio-img{
        position: relative;
        z-index: 1;
        transition: all .5s ease;
        overflow: hidden;
        img{
            transition: all .4s ease;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }

        &:after{
            position: absolute;
            content:"";
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: $primary-color;
            background: rgba(255, 83, 105,.9);
            opacity: 0;
            transition: all .5s ease;
        }

        .overlay-content{
            position: absolute;
            bottom: 0px;
            left: 50px;
            z-index: 1;
            opacity: 0;
            transform: translateY(0px);
            transition: all .5s ease;

            h4{
                color: $light;
                text-transform: capitalize;
                a{
                    position: relative;
                    padding-bottom: 10px;
                    &::before{
                        position: absolute;
                        content:"";
                        left: 0px;
                        bottom: 0px;
                        width: 20%;
                        height: 1px;
                        background: $light;
                        transition: all .4s ease;
                    }
                    &:hover{
                        color: $light;
                        &:before{
                            width: 100%;
                        }
                    }
                }
            }
            span{
                color: $light;
                text-transform: capitalize;
            }
        }
        .overlay-icon{
            position: absolute;
            right: 0px;
            top: 30px;
            z-index: 1;
            opacity: 0;
            transform: translateX(0px);
            transition: all .5s ease;
            a{
                width: 50px;
                height: 50px;
                background: rgba(255,255,255,1);
                display: inline-block;
                text-align: center;
                border-radius: 100%;
                line-height: 50px;

            }
        }

        &:hover{
            &:after{
                opacity: 1;
            }
            .overlay-content{
                transform: translateY(-50px);
                opacity: 1;
               
            }
            .overlay-icon{
                transform: translateX(-30px);
                opacity: 1;
            }
        }
    }
}




/*-----------------------
portflio filter
-------------------------------*/

.portfolio-filter {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 50px;
    padding-bottom: 15px;
    text-align: right;

    li {
        display: inline-block;
        margin: 0px 5px;

        &.active{
            a{
                color: #363ace;
                border-color: #363ace;
            }
        }
        a {
            letter-spacing: 2px;
            color: #000;
            border-bottom: 1px solid transparent;
            padding: 10px 15px;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
            font-family: $primary-font;
        }
    }
}


.portfolio-gallery{
    .portfolio-single{
       border: 0px;
       margin-bottom: 25px;
       overflow: hidden;
       border-radius: 4px;
      
       img{
           border-radius: 4px;
       }

       .portfolio-img{
           img{
               transition: all .4s ease;
           }
           &::after {
                border-radius: 4px;
                background: rgba(54, 58, 206,.8);
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
       }

       .overlay-icon a{
           color: #363ace;
           &:hover{
               background: #363ace;
               color: $light;
           }
       }

       .overlay-content a{
           &:hover{
               color: $green-color;
           }
       }

    }
}




.portfolio-single.portfolio-layout-2{
    border: 0px;
    
    .portfolio-img .overlay-content {
        left: calc(100% - 90%);
    }

    .overlay-content a{
        position: relative;
        &::before{
            position: absolute;
            content:"";
            left: 0px;
            bottom: 0px;
            width: 10%;
            height: 1px;
            background: $light;
            transition: all .4s ease;
        }
        &:hover{
            color: $light;
           &:before{
               width: 100%;
           }
        }
    }
}